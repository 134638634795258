import { useMemo, FC } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { useQueryClient, useMutation } from 'react-query';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { usePopover } from '../../../../hooks/use-popover';
import { DotsVertical as DotsVerticalIcon } from '../../../../icons/dots-vertical';
import { Flag as FlagIcon } from '../../../../icons/flag';
import { Ban as DeactivateItemIcon } from '../../../../icons/ban';
import { useDialog } from '../../../../hooks/use-dialog';
import { JobFlagDialog } from '../../../../components/job/job-flag-dialog';
import { FlagType, Job } from '../../../../types/job';
import { JobRevertFlagDialog } from '../../../../components/job/job-revert-flag-dialog';
import { ConfirmationDialog } from '../../../../components/confirmation-dialog';
import { useAuth } from '../../../../hooks/use-auth';
import { useAxios } from '../../../../hooks/use-axios';
import { ResponseData } from '../../../../types/axios';
import { Item } from '../../../../types/item';
import logger from '../../../../utils/logger';

interface JobsMenuProps {
  job: Job;
}

export const JobsMenu: FC<JobsMenuProps> = ({ job }) => {
  const [anchorRef, open, handleOpen, handleClose] = usePopover();
  const [openFlag, handleOpenFlag, handleCloseFlag] = useDialog();
  const [openRevertFlag, handleOpenRevertFlag, handleCloseRevertFlag] = useDialog();
  const [deactivateOpen, handleDeactivateOpen, handleDeactivateClose] = useDialog();

  const queryClient = useQueryClient();
  const { axios } = useAxios();
  const { tenant } = useAuth();

  const handleFlag = (): void => {
    handleClose();
    handleOpenFlag();
  };

  const handleRevertFlag = (): void => {
    handleClose();
    handleOpenRevertFlag();
  };

  const deactivateMutation = useMutation(
    async () => {
      if (!tenant) {
        throw new Error('Tenant is missing');
      }
      const url = `/items/${job.item?.id}`;

      const data = {
        publicMetadata: {
          deactivated: true,
        },
      };

      return axios.put<ResponseData<Item>>(url, data);
    },
    {
      onSuccess: () => {
        toast.success('Item was deactivated');
        queryClient.invalidateQueries('jobs');
      },
      onError: (err: any) => {
        logger(err);
        toast.error(err.message || 'Deactivating item failed');
      },
      onSettled: () => {
        handleDeactivateClose();
      },
    },
  );

  const handleDeactivate = () => {
    deactivateMutation.mutate();
  };

  const flagJobAction = useMemo(
    () =>
      job.flag === FlagType.Incorrect ? (
        <MenuItem onClick={handleRevertFlag}>
          <FlagIcon color="action" sx={{ mr: 1 }} />
          Revert Report
        </MenuItem>
      ) : (
        <MenuItem onClick={handleFlag}>
          <FlagIcon color="error" sx={{ mr: 1 }} />
          Report Event
        </MenuItem>
      ),

    [job],
  );

  const deactivateItemAction = useMemo(
    () =>
      job.item &&
      !job.item?.publicMetadata?.deactivated && (
        <MenuItem onClick={handleDeactivateOpen}>
          <DeactivateItemIcon color="action" sx={{ mr: 1 }} />
          Deactivate Item
        </MenuItem>
      ),

    [job],
  );

  return (
    <>
      <IconButton onClick={handleOpen} ref={anchorRef}>
        <DotsVerticalIcon fontSize="small" />
      </IconButton>
      <Menu
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {flagJobAction}
        {deactivateItemAction}
      </Menu>
      <JobFlagDialog job={job} open={openFlag} onClose={handleCloseFlag} />
      <JobRevertFlagDialog job={job} open={openRevertFlag} onClose={handleCloseRevertFlag} />
      <ConfirmationDialog
        message={`Are you sure you want to deactivate this item: „${job.item?.uniqueId}“?`}
        onCancel={handleDeactivateClose}
        onConfirm={handleDeactivate}
        open={deactivateOpen}
        title="Deactivate Item"
        variant="warning"
      />
    </>
  );
};

JobsMenu.propTypes = {
  job: PropTypes.any.isRequired,
};
