import { useCallback, FC, useMemo } from 'react';
import Proptypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import toast from 'react-hot-toast';
import { Box, Button, IconButton } from '@material-ui/core';
import { useMutation, useQueryClient } from 'react-query';
import { Item } from '../../../../types/item';
import { ConfirmationDialog } from '../../../../components/confirmation-dialog';
import { useDialog } from '../../../../hooks/use-dialog';
import { Trash as TrashIcon } from '../../../../icons/trash';
import { useAuth } from '../../../../hooks/use-auth';
import logger from '../../../../utils/logger';
import { useAxios } from '../../../../hooks/use-axios';
import { usePermissions } from '../../../../hooks/use-permissions';
import { Permission } from '../../../../types/user';
import { TenantType } from '../../../../types/tenant';

interface ItemsActionsProps {
  item: Item;
}

export const ItemsActions: FC<ItemsActionsProps> = ({ item }) => {
  const [openDelete, handleOpenDelete, handleCloseDelete] = useDialog();
  const { tenant, getTenantTypes } = useAuth();
  const { axios } = useAxios();
  const { checkPermission } = usePermissions();
  const queryClient = useQueryClient();

  const deleteMutation = useMutation(
    async () => {
      if (!tenant) {
        throw new Error('Tenant is missing');
      }

      const url = `/items/${item.id}`;

      return axios.delete(url);
    },
    {
      onSuccess: () => {
        toast.success('Item was deleted');
        queryClient.invalidateQueries('items');
        queryClient.invalidateQueries('sku-items');
        queryClient.invalidateQueries('sku-items-registered');
        queryClient.invalidateQueries('sku-items-registered-count');
        handleCloseDelete();
      },
      onError: (err) => {
        logger(err);
        toast.error('Deleting item failed');
      },
    },
  );

  const handleDeleteItem = useCallback(() => {
    deleteMutation.mutate();
  }, [deleteMutation]);

  const showVerifyButton = useMemo(() => {
    if (!item || !getTenantTypes().includes(TenantType.AUTHENTICATION)) {
      return false;
    }

    if (!checkPermission(Permission.TENANT_ITEMS_VERIFY)) {
      return false;
    }

    return item.isProtected;
  }, [item, getTenantTypes, checkPermission]);

  const showProtectButton = useMemo(() => {
    if (!item || !getTenantTypes().includes(TenantType.AUTHENTICATION)) {
      return false;
    }

    if (!checkPermission(Permission.TENANT_ITEMS_PROTECT)) {
      return false;
    }

    return !item.isProtected;
  }, [item, getTenantTypes]);

  // Cannot delete registered items (identification worker is using them)
  const showDeleteButton = useMemo(() => {
    if (!item || item.isRegistered) {
      return false;
    }

    return checkPermission(Permission.TENANT_ITEMS_WRITE);
  }, [item, checkPermission]);

  return (
    <>
      <Box
        display="flex"
        sx={{
          justifyContent: 'flex-end',
          gridGap: '1rem',
        }}
      >
        {showProtectButton && (
          <Button
            component={RouterLink}
            to={`/tenants/${tenant?.id}/items/${item.id}/protect`}
            disabled={item.publicMetadata?.deactivated}
            variant="outlined"
          >
            Protect
          </Button>
        )}
        {showVerifyButton && (
          <Button
            component={RouterLink}
            to={`/tenants/${tenant?.id}/items/${item.id}/verify`}
            disabled={item.publicMetadata?.deactivated}
            variant="outlined"
          >
            Verify
          </Button>
        )}
        <Button
          component={RouterLink}
          to={`/tenants/${tenant?.id}/items/${item.id}`}
          variant="text"
        >
          Preview
        </Button>
        {showDeleteButton && (
          <IconButton
            sx={{
              p: 0,
            }}
            onClick={handleOpenDelete}
          >
            <TrashIcon />
          </IconButton>
        )}
      </Box>

      <ConfirmationDialog
        message={`Are you sure you want to delete item with unique ID: „${item.uniqueId}“? This can't be undone.`}
        onCancel={handleCloseDelete}
        onConfirm={handleDeleteItem}
        open={openDelete}
        title="Delete Item"
        variant="error"
      />
    </>
  );
};

ItemsActions.propTypes = {
  item: Proptypes.any.isRequired,
};
